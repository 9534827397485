.order-details-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.order-details-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

.order-items {
  width: 100%;
  margin-bottom: 10px;
}

.order-items h3 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #00a1b6;
}

.order-items ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.order-items li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.order-items .item-name {
  color: #00a1b6;
}

.order-items .item-quantity {
  color: #e9a40f;
}

.order-total {
  overflow-x: auto;
  width: 100%;
}

.order-total h3 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #00a1b6;
}

.order-total p {
  font-size: 16px;
  margin-bottom: 5px;
  color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .order-details-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-items {
    width: 100%;
  }
}
