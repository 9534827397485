.spinner{
    min-height: 20vh;
}
.dot {
  color: red;
  width: 15px;
  aspect-ratio: 1;
  position: relative;
}
.dot::before,
.dot::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
}
.dot::before {
  background: rgb(0, 255, 72);
  box-shadow: -26px 0;
  animation: d10-1 1.5s infinite linear;
}
.dot::after {
  background: rgb(0, 81, 255);
  transform: rotate(0deg) translateX(26px);
  animation: d10-2 1.5s infinite linear;
}
@keyframes d10-1 {
  50% {
    transform: translateX(26px);
  }
}
@keyframes d10-2 {
  100% {
    transform: rotate(-360deg) translateX(26px);
  }
}

 .classic {
  font-size: 20px;
  clip-path: inset(0 3ch 0 0);
  animation:c4 1s steps(4) infinite;
}
@keyframes c4 {to{clip-path: inset(0 -1ch 0 0)}}


/* _____________________ */


.loader  {
  animation: rotate 1s infinite;  
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {   
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;  
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;  
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite; 
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% { 
    -webkit-transform: rotate(0deg) scale(0.8); 
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% { 
    -webkit-transform: rotate(360deg) scale(1.2); 
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% { 
    -webkit-transform: rotate(720deg) scale(0.8); 
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}