.feedback_form{
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
}
.error_message{
    color: red;
    font-size: small;
    padding-left: 5px;
}
@media only screen and (max-width:1024px) {   
    .feedback_form{
        width: 80vw;
    }
}

@media only screen and (max-width:500px) {   
    .feedback_form{
        width: 100vw;
    }
}
