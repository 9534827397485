.payment_methods {
  width: 80%;
}
.payment_methods div:hover {
  cursor: pointer;
}
.payment_container {
  width: 70vw;
}
.cancel-btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  border: 1px solid var(--ternary-theme-color);
  border-radius: 5px;
  color: var(--ternary-theme-color);
  padding: 0.5rem 1.3rem;
  text-transform: capitalize;
  margin: 10px 0px;
}
.cancel-btn:hover {
  cursor: pointer;
  border: 1px solid var(--main-theme-color);
  color: var(--main-theme-color);
}
.next-btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ternary-theme-color);
  border: 1px solid var(--ternary-theme-color);
  border-radius: 5px;
  color: var(--white-color);
  padding: 0.5rem 1.3rem;
  text-transform: capitalize;
  margin: 10px 0px;
}
.next-btn:hover {
  cursor: pointer;
  background-color: var(--main-theme-color);
}
@media only screen and (max-width: 1000px) {
  .payment_container {
    width: 70vw;
  }
}
@media only screen and (max-width: 700px) {
  .payment_container {
    width: 90vw;
  }
}
@media only screen and (max-width: 500px) {
  .payment_container {
    width: 95vw;
  }
}
