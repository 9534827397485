@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.whatsapp_link {
  position: fixed;
  bottom: 0.5%;
  right: 0.5%;
  margin: 10px;
  padding: 10px;
  width: fit-content;
  height: fit-content;
  background-color: var(--green-whatsapp);
  color: var(--white-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0px 0px 30px 0px #1f1f1f33;
}
.whatsapp_link:hover {
  background-color: var(--green-whatsapp-hover);
  color: var(--white-color);
  cursor: pointer;
}

.slick-list {
  border-radius: 1rem;
}
.slider {
  width: 80%;
  min-height: 60vh;
}

.slider-row {
  background-color: #C1DCDC;
}

.slider-left {
  min-height: 60vh;
  padding: 3rem;
}
.slider-left .slider-heading {
  color: #005e6a;
  font-weight: 600;
}
.slider-left .slider-para {
  width: 70%;
  color: #224949;
}

.home_search_box {
  background: #fff;
  width: fit-content;
  border-radius: 2rem;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #fff;
}

.home_search_box:hover {
  border: 2px solid #00aac0;
}

.main_search_btn {
  width: 42px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  background: #fff;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-right {
  background-image: url("../../assets/SliderBG.svg");
  background-position-y: top;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.slider-right .slider-right-image {
  width: 80%;
}

.future_prods {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas:
    "future_prods_1 future_prods_2 future_prods_2"
    "future_prods_1 future_prods_3 future_prods_4";
  row-gap: 10px;
  column-gap: 10px;
}
.future_prods_1 {
  grid-area: future_prods_1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.future_prods .card-img-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
}
.future_prods_1:hover,
.future_prods_2:hover,
.future_prods_3:hover,
.future_prods_4:hover {
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s ease-in-out;
}

.future_prods_2 {
  grid-area: future_prods_2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.future_prods_3 {
  grid-area: future_prods_3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.future_prods_4 {
  grid-area: future_prods_4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.future_prods_1 img,
.future_prods_2 img,
.future_prods_3 img,
.future_prods_4 img {
  width: 50%;
}
.customer-reviews {
  background-color: #d8fbff;
  width: 100%;
  margin-top: 5rem;
  position: relative;
  padding: 4rem 2rem;
}
.customer-reviews .customer-reviews-row {
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: center;
}

.marquee-container {
  height: 50vh;
  margin: 0;
}
.marquee-container .overlay {
  visibility: hidden;
}
.customer-reviews .customer-reviews-row .review-card {
  height: auto;
}

.customer-reviews .customerReview-card1 {
  background-color: #fff;
  padding: 1.5rem;
  width: 300px;
  border-radius: 15px;
  box-shadow: 0px 6px 47px 0px #264e761a;
  margin-left: 1rem;
  margin-right: 1rem;
  transform: rotate(2deg);
}

.customer-reviews .customerReview-card1:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.customer-reviews .customerReview-card2 {
  background-color: #fff;
  transform: rotate(-2deg);
  width: 300px;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0px 6px 47px 0px #264e761a;
  margin-left: 1rem;
  margin-right: 1rem;
}

.customer-reviews .customerReview-card2:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.05);
}

.customer-reviews .customerReview-card1 .customer-review {
  font-size: 1rem;
}

.customer-reviews .customerReview-card2 .customer-review {
  font-size: 1rem;
}
.customer_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.customer_pic {
  border: 1px solid #0093a7;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.customer_pic img {
  width: 2.5rem;
  border-radius: 50%;
}
.customer_name {
  font-weight: 500;
}

/* _______________________ */
.card_flip {
  width: 300px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  perspective: 500px;
  margin-top: 1rem;
}

.flip_content {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transition: transform 1s;
  border-radius: 10px;
  transform-style: preserve-3d;
  background-color: none;
}

.card_flip:hover .flip_content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.front_card {
  position: absolute;
  height: 250px;
  width: 300px;
  backface-visibility: hidden;
  display: grid;
  border: 2px solid #dddddd1f;
  border-radius: 10px;
  place-items: center;
  background-color: var(--white-color);
  box-shadow: 0px 6px 47px 0px #264e761a;
}

.back_card {
  position: absolute;
  height: 250px;
  width: 300px;
  border-radius: 10px;
  background: linear-gradient(
    132.05deg,
    #00aac0 1.25%,
    #0093a7 48.43%,
    #00aac0 99.05%
  );
  color: var(--white-color);
  backface-visibility: hidden;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #dddddd1f;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  .slider-row {
    flex-direction: column;
  }

  .home_search_box {
    width: 70%;
  }

  .slider-left {
    width: 100vw;
    order: 1;
  }
  .slider-left .slider-para {
    width: 90%;
  }
  .slider-right {
    order: 2;
    width: 100vw;
  }

  .future_prods {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "future_prods_1 future_prods_2 "
      " future_prods_3 future_prods_4";
  }
  .future_prods_1 img,
  .future_prods_2 img,
  .future_prods_3 img,
  .future_prods_4 img {
    width: 90%;
  }
}
@media only screen and (max-width: 500px) {
  .home_search_box {
    width: 90%;
    padding: 5px 10px;
  }

  .slider-left {
    padding: 2rem;
    min-height: fit-content;
    height: 50vh;
  }
  .slider-left .slider-para {
    width: 95%;
  }
  .customer-reviews .customerReview-card1 .customer-review {
    font-size: 0.8rem;
  }

  .customer-reviews .customerReview-card2 .customer-review {
    font-size: 0.8rem;
  }

  .customer-reviews .customerReview-card1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 250px;
    padding: 10px 15px;
  }
  .customer-reviews .customerReview-card2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 250px;
    padding: 10px 15px;
  }
  .customer_pic img {
    width: 2rem;
  }
}

/* CSS */
.button-52 {
  background-color: var(--main-theme-color);
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  text-transform: capitalize;
  text-decoration: none;
  color: var(--white-color);
  border: 1px solid var(--white-color);
}
.button-52:hover {
  background-color: var(--white-color);
  color: var(--main-theme-color);
}
