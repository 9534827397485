.footer {
  background-color: #005e6a;
  width: 100%;
  margin-top: 15rem;
  position: relative;
  padding: 2rem;
  height: auto;
  color: white;
}

.footer:before {
  position: absolute;
  top: 1;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  transform-origin: bottom left;
  transform: skewY(-3deg);
  background: #00a1b6;
}

.footer:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  transform-origin: top right;
  transform: skewY(4deg);
  background: inherit;
}
.hr {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.footer-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.footer-body .footer-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-body .footer-services {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-body .footer-services ul {
  padding: 0;
}
.footer-body .footer-services ul li {
  list-style: none;
  font-size: 15px;
  margin-top: 5px;
}
.footer-body .footer-services ul li a {
  color: #ffffff;
  text-decoration: none;
}

.footer-body .footer-services ul li a:hover {
  text-decoration: underline;
  cursor: pointer;
  text-decoration: underline;
}

.footer .footer-bottom {
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.footer .footer-bottom .terms {
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.footer .footer-bottom .terms li {
  list-style: none;
  font-size: 15px;
  margin-top: 5px;
}
.footer .footer-bottom .terms li:hover {
  cursor: pointer;
  color: #00a1b6;
}

.footer .footer-bottom .social-icons {
  margin: 0px;
}

.footer .footer-bottom .footer-tnc,
.privacyPolicy,
.social-icons:hover {
  cursor: pointer;
}

.footer .footer-bottom .social-icons .footer-icon {
  color: white;
  font-size: 1.2rem;
  margin: 5px;
}

.subscribe_section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.subscribe_section p {
  color: rgba(255, 255, 255, 0.86);
}
.subscribe_heading {
  font-size: 25px;
  margin: 10px 0px;
}
.subscribe_form {
  background-color: rgba(255, 255, 255, 0.168);
  border-radius: 50px;
  padding: 5px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.subscribe_now {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  border-radius: 50px;
  font-size: 15px;
  height: 50px;
  padding: 12px 20px;
  color: #fff;
}
.subscribe_now::placeholder {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.86);
}
.subscribe_now:focus {
  outline: 0;
}
.subscribe_btn {
  border-radius: 50px;
  background-color: #00a1b6;
  color: #fff;
  padding: 5px 18px;
  outline: none;
  border: 0;
  height: 40px;
}

.subscribe_btn:hover {
  background-color: #0098ac;
}

@media only screen and (max-width: 900px) {
  .footer {
    padding: 1rem;
  }
  .footer-body .footer-logo img {
    width: 240px;
    margin: 1rem;
  }

  .subscribe_now {
    height: 50px;
    padding: 5px 20px;
    width: fit-content;
  }
}

@media only screen and (max-width: 700px) {
  .footer {
    padding: 0.5rem;
  }
  .footer-body .footer-logo img {
    width: 200px;
  }
  .subscribe_form {
    padding: 5px 7px;
  }
  .subscribe_now {
    font-size: 15px;
    height: 50px;
    padding: 10px 18px;
  }
  .subscribe_now::placeholder {
    font-size: 15px;
  }
  .subscribe_btn {
    padding: 5px 10px;
    height: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 0;
  }

  .subscribe_form {
    background-color: rgba(255, 255, 255, 0);
    padding: 1px 3px;
    flex-direction: column;
    border-radius: 0px;
    width: 95%;
  }
  .subscribe_now {
    width: 95%;
    background-color: rgba(255, 255, 255, 0.186);
    border: 0;
    border-radius: 50px;
    padding: 7px 10px;
    margin: 2px 0px;
  }
  .subscribe_now::placeholder {
    padding: 1rem;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.86);
  }

  .footer-body {
    flex-direction: column;
  }
  .footer-body .footer-logo img {
    width: 250px;
  }

  .footer .footer-bottom {
    flex-direction: column;
  }
  .footer .footer-bottom .social-icons .footer-icon {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 350px) {
  .subscribe_now {
    font-size: 12px;
    height: 45px;
    padding: 3px 6px;
  }
  .subscribe_now::placeholder {
    font-size: 12px;
  }
}
