.products-page {
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr;
  grid-template-areas: "products-sidebar products-list";
}

.products-sidebar {
  background: #00899b;
}

.products-page .products-sidebar {
  grid-area: products-sidebar;
  height: 100vh;
  width: auto;
  position: sticky;
  top: 0px;
}

.products-sidebar .sidebar-body {
  background: #d6f7f7;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  height: 100vh;
}
.products-sidebar .sidebar-body::-webkit-scrollbar {
  width: 0px;
}

.products-sidebar .sidebar-body .sidebar-ul {
  margin: 0;
  padding: 0;
}
.products-sidebar .sidebar-body .sidebar-ul .sidebar-li {
  height: 40px;
  list-style: none;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  color: #686868;
  text-decoration: none;
  margin: 2px 5px;
  border-radius: 10px;
}
.products-sidebar .sidebar-body .sidebar-ul .sidebar-li.active {
  color: #ffffff;
}

.products-sidebar .sidebar-body .sidebar-ul .sidebar-li:hover {
  cursor: pointer;
  background-color: #00a1b6;
  color: white;
}

.active {
  background-color: #00a1b6;
  color: #fff;
}

.products-list {
  grid-area: products-list;
  padding-top: 2rem;
  padding-right: 0;
  padding-left: 0;
  margin: 0;
  width: auto;
}

.products-list-body {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.5rem;
}
.product-card {
  border-radius: 10px;
  padding: 1rem;
  height: auto;
  margin: 1rem 10px 0 5px;
  box-shadow: 0px 2px 10px 2px rgba(161, 161, 161, 0.1);
  transition: all 0.3s ease-in-out;
}

.product-card:hover {
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 5px 15px 0px #5454542c;
  scale: 103%;
}

.product-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(50px);
  z-index: -1;
}

.product-foreground {
  z-index: 1;
}

.product-image {
  width: 220px;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  z-index: 1;
}

.product-image img {
  transition: all 01s ease-in-out;
  border-radius: 3px;
  width: 200px;
  height: auto;
}
.product-image .over-image {
  z-index: 1;
  position: absolute;
  bottom: 0%;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px 10px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.075) 0%,
    rgba(0, 0, 0, 0.25) 122.83%
  );
  background-blend-mode: darken;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #289aff;
}

.In-Stock {
  background-color: #d8ffe0;
  color: #03cd0b;
  width: fit-content;
  border-radius: 2px;
  padding: 3px 10px;
}
.Currently-Unavailable {
  background-color: #ffd7d7;
  color: #ff001f;
  width: fit-content;
  border-radius: 2px;
  padding: 3px 10px;
}
.save-percentage {
  border-radius: 5px;
  background-color: #03cd0b;
  color: white;
  padding: 0 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1rem;
}

.over-image .wishlist-btn {
  border-radius: 50%;
  width: fit-content;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a1b6;
  color: white;
  font-size: 1rem;
}

.over-image .wishlist-btn:hover {
  border: 1px solid #00a1b6;
  padding: 2px auto;
  border: none;
  cursor: pointer;
}

.product-title a {
  width: fit-content;
  font-size: 18px;
  color: #000;
}

.product-title a:hover {
  color: #00a1b6;
}

.price-rating {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.products-ratings {
  font-size: 12px;
}

.star_rating {
  background-color: orange;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
}
.product_price_card {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}
.product-price {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.product_price_card .line_through_text {
  color: grey;
  font-size: 15px;
  text-decoration: line-through;
}
.product-price .line_through_text {
  color: grey;
  font-size: 15px;
  text-decoration: line-through;
}
.saving {
  color: #07a945;
  margin: 2px 5px;
  font-weight: 300;
}

/* PRODUCTID PAGE CSS STARTS  */

.productID-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  position: sticky;
  top: 0;
  left: 0;
}
.productID-left img {
  width: 250px;
  margin: 1rem 0rem;
}
.productID-right {
  padding-top: auto;
  padding-bottom: auto;
}
.productID-right .prod_heading {
  /* color: #00a1b6; */
  font-size: 2rem;
}
.product-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  width: fit-content;
}

.product-buttons .cart-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00a1b6;
  border-radius: 5px;
  color: #00a1b6;
  padding: 0.7rem 1rem;
  text-transform: capitalize;
}

.product-buttons .cart-btn:hover {
  cursor: pointer;
  color: #00899b;
  border: 1px solid #00899b;
}

.product-buttons .buy-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a1b6;
  border: 1px solid #00a1b6;
  color: #ffffff;
  padding: 0.7rem 1.3rem;
  text-transform: capitalize;
}

.product-buttons .buy-btn:hover {
  cursor: pointer;
  background-color: #00899b;
}

tbody tr td {
  padding: 5px 10px;
}

.about_product_table {
  width: 100%;
  margin-bottom: 1rem;
}
.about_product_table tbody {
  width: 100%;
}

.light_text {
  color: rgb(155, 155, 155);
}
.product_description {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  padding: 5px 10px;
}
.read_more {
  color: #289aff;
}
.read_more:hover {
  cursor: pointer;
}
/* PRODUCTID PAGE CSS ENDS */

@media only screen and (max-width: 500px) {
  .products-page {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "products-sidebar products-sidebar"
      "products-list products-list";
  }
  .productID-left {
    position: static;
    top: 0;
    left: 0;
    height: auto;
  }

  .productID-right .prod_heading {
    font-size: 1.5rem;
  }

  .products-sidebar .sidebar-body .sidebar-ul .sidebar-li:hover {
    background-color: #d6f7f7;
    color: #00899b;
  }
  .active {
    background-color: #d6f7f7;
    color: #00899b;
  }

  .product-card {
    width: 150px;
    padding: 0.3rem;
    margin: 5px 2px;
    height: auto;
  }
  .product-image {
    width: 100%;
    height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-image img {
    width: 120px;
    height: auto;
  }
  .product-title a {
    font-size: 15px;
  }
  .product_price_card {
    font-size: 15px;
  }

  .product_price_card .line_through_text {
    font-size: 12px;
  }

  .products-ratings {
    font-size: 10px;
  }

  .save-percentage {
    padding: 0 5px;
    font-size: 0.8rem;
  }

  .over-image .wishlist-btn {
    padding: 3px;
    font-size: 0.8rem;
  }

  .products-page .products-sidebar {
    height: fit-content;
    width: fit-content;
    z-index: 2;
    position: relative;
    top: 0px;
  }
  .products-list {
    grid-area: products-list;
    width: 100vw;
  }

  .products-list .products-list-body {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.5rem;
  }

  .products-sidebar .sidebar-body {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding-top: 10px;
    padding-bottom: 10px;
    height: fit-content;
  }

  .products-sidebar .sidebar-body .sidebar-ul {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: auto;
  }

  .products-sidebar .sidebar-body .sidebar-ul .sidebar-li {
    height: auto;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    border-radius: 5px;
  }  
  .products-sidebar .sidebar-body .sidebar-ul .sidebar-li.active {
    color: #00899b;
  }

  .productID-right .product-buttons {
    justify-content: center;
  }
}

.scroll-top {
  position: fixed;
  bottom: 0.5%;
  right: 0.5%;
  font-size: 50px;
  margin: 10px;
  background-color: #fff;
  width: fit-content;
  height: fit-content;
  border-radius: 100%;
  padding: 0px;
  color: #00899b;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 10px 0px #ffffff80 inset, 0px 0px 30px 0px #1f1f1f33;
  z-index: 2;
}

.scroll-top:hover {
  color: #007484;
  cursor: pointer;
}
