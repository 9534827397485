ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c20 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1400000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 8pt;
}

.c33 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 10pt;
}

.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1400000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5pt;
}

.c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.14;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c19 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 13pt;
}

.c29 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c15 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1400000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 12pt;
}

.c26 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1400000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 10pt;
}

.c28 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1400000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt;
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1400000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 6pt;
}

.c27 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.16;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 6pt;
}

.c38 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.16;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 9pt;
}

.c12 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c36 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.13;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5pt;
}

.c0 {
  color: #454545;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}

.c31 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.19;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 13pt;
}

.c8 {
  color: #454545;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: "Arial";
  font-style: normal;
}

.c37 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3272727272727272;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c3 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9090909090909091;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c22 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.5954545454545455;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c21 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9090909090909091;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c1 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9636363636363636;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c32 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9772727272727273;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c17 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3681818181818182;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c35 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3363636363636362;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c14 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9590909090909091;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c5 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c18 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3772727272727272;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c24 {
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}

.c10 {
  font-size: 13pt;
  color: #454545;
  font-weight: 700;
}

.c34 {
  color: #000000;
  font-weight: 400;
  font-size: 11pt;
}

.c25 {
  background-color: #ffffff;
  padding: 0pt 72pt 72pt 72pt;
}

.c9 {
  color: inherit;
  text-decoration: inherit;
}

.c6 {
  color: #e4af0a;
  font-size: 12pt;
}

.c4 {
  color: #454545;
  font-size: 12pt;
}

.c30 {
  font-weight: 400;
  font-family: "Times New Roman";
}

.c16 {
  height: 11pt;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
/* 
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}

h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
} */
