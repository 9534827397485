.my_btn {
  position: relative;
  bottom: 20%;
  width: 50%;
  height: fit-content;
}
.floating_btn {
  position: relative;
  bottom: 5%;
  right: 2%;
  margin: 10px;
  margin-left: auto;
  width: 60px;
  height: 60px;
  padding: 0.5rem;
  font-size: 1.5rem;
  background-color: #00a3ff;
  color: var(--white-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0px 0px 15px 0px #7e7e7e33;
}
.floating_btn:hover {
  background-color: #0089d8;
  color: var(--white-color);
  cursor: pointer;
}
.write_blog {
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 60px;
  padding: 1rem;
  right: 2%;
  z-index: 3;
  box-shadow: 0px 5px 10px 2px #5c5c5c33;
}

@media only screen and (max-width: 1000px) {
  .my_btn {
    width: 90%;
  }
  .write_blog {
    right: 0%;
  }
}

@media only screen and (max-width: 600px) {
  .my_btn {
    width: 100%;
  }
}
